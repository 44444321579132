import { useNavigate } from '@remix-run/react'
import { useSetAtom } from 'jotai'
import { LucidePlusCircle } from 'lucide-react'
import { useState } from 'react'
import { aCheckedListings } from '~/atoms'
import { Button, Command } from '~/components/ui'
import { Report } from '~/models'

export default function ReportAddToExisting({
  reports,
  checkedListings,
  buttonText = 'Add to Existing',
  buttonVariant = 'ghost',
  className = 'w-full justify-start !bg-transparent pl-2',
  showIcon = true,
}: {
  reports: Report[]
  checkedListings: string[]
  buttonText?: string
  buttonVariant?: 'ghost' | 'outline'
  className?: string
  showIcon?: boolean
}) {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const setCheckedListings = useSetAtom(aCheckedListings)

  return (
    <>
      <Button
        variant={buttonVariant}
        size="sm"
        className={className}
        onClick={() => setIsOpen(!isOpen)}>
        {showIcon && (
          <LucidePlusCircle className="mr-2 h-4 w-4 text-muted-foreground" />
        )}
        {buttonText}
      </Button>
      <Command.Dialog open={isOpen} onOpenChange={setIsOpen}>
        <Command>
          <Command.Input placeholder="Filter static reports..." />
          <Command.Empty>No reports found.</Command.Empty>
          <Command.Group className="max-h-[400px] overflow-auto">
            {reports.map((report) => {
              return (
                <Command.Item
                  key={report._id}
                  value={report.name}
                  className="cursor-pointer"
                  onSelect={() => {
                    navigate(`/admin/reports/${report.slug}/edit`, {
                      state: { listingIds: checkedListings },
                    })
                    setIsOpen(false)
                    setCheckedListings([])
                  }}>
                  {report.name}
                </Command.Item>
              )
            })}
          </Command.Group>
        </Command>
      </Command.Dialog>
    </>
  )
}
